import React, {useState, Fragment} from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Hidden } from 'react-grid-system'
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { Navigation } from "./nav/links"

import Logo from "../../../images/img/logo.png"

export default function Header() {

  // Mobile Dropdown Navigation
  function mobToggle(e) {
    let target = e.target
    target.classList.toggle("active");
  }

  return (
    <div className="nav-container sticky top-0 md:absolute w-full">
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <Container className="py-3 flex items-center justify-between md:block nav-wrap">
              <div className="flex items-center">
                <div className="nav-logo">
                  <Link to='/'>
                    <img className="w-3/5" src={Logo} alt="Logo" />
                  </Link>
                </div>
                <div className="navigation-wrapper hidden md:ml-6 md:flex">
                  <ul className="flex">
                  {Navigation.map((link, i) => (
                    <li className="relative">
                      <Link
                        key={i}
                        to={link.link}
                        className="inline-flex items-center pt-1 mr-20 text-white parent"
                        activeClassName="border-indigo-500  active"
                      >
                        {link.title}
                      </Link>
                      <ul className="flex">
                        {link.subnav.map(sublink => 
                          <li>
                            <Link
                              key={i}
                              to={sublink.link}
                              className="inline-flex items-center pt-1 mr-20"
                              activeClassName="border-indigo-500 active"
                            >
                              {sublink.title}
                            </Link>
                          </li>
                        )}                                              
                      </ul>
                    </li>
                  ))}
                  </ul>
                </div>
              </div>
              {/* Mobile Navigation */}
              <div className="flex items-center md:hidden">
                <Disclosure.Button aria-label="Mobile Menu" className="inline-flex burger-container items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-12 w-12" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-12 w-12" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
          </Container>
          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1 mobile-menu">
              <ul>
                {Navigation.map((link, i) => (
                    <li onClick={mobToggle} className={`${link.subnav.length > 1 ? 'parent-link' : ''}`}>
                      <Link
                        key={i}
                        to={link.link}
                        className="border-t-2 block pl-3 pr-4 py-4 font-medium"
                        activeClassName="border-indigo-500  active"
                      >
                        {link.title}
                      </Link>
                      {link.subnav.map(sublink => 
                          <Link
                            key={i}
                            to={sublink.link}
                            className="border-t-2 block pl-3 pr-4 py-4 font-medium hidden"
                            activeClassName="border-indigo-500 active"
                          >
                            {sublink.title}
                          </Link>
                      )}
                    </li>                   
                ))}
              </ul>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    </div>
  )
}
import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-grid-system'
import { Social } from '../header/nav/links'

import Logo from '../../../images/img/logo.png'

export default function Footer() {

    return (
        <footer aria-label="Footer">
            <Container className="py-16">
                <div className="text-center">
                    <Link to="/"><img src={Logo} className="mx-auto" alt="Logo" /></Link>
                    <div className="flex justify-center icons-wrap my-10">
                        {/* {Social.map((link, i) => (
                            <span key={i}>
                                <a href={link.href} title={link.name} target="_blank" rel="noopener noreferrer">
                                    {link.icon}
                                </a>
                            </span>
                        ))} */}
                    </div>
                    <h6>260 Whitfield Crescent, Midland, Ontario Canada L4R 5E3</h6>
                    <p className="text-blue-100">&copy; 2022 RF Kiln Tech. All rights reserved.</p>
                </div>
            </Container>
      </footer>
    )
}
